import React,{useState} from 'react'
import { Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import Modal from '@solid-ui-components/Modal'
import ListItem from '@solid-ui-components/ListItem'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import ContentMap from '@solid-ui-components/ContentMap'
import ContentForm from '@solid-ui-components/ContentForm'
const ModalBlock02 = ({
  content: { identifier, text, images, map, collection, form, buttons },
  reverse
}) => {
  const hasMedia = images || map

  const style = {
  
    div: {
      marginTop: "-11px"
    },
    input: {
      boxSizing: "border-box",
      minWidth: "0",
      display: "block",
      width: "80%",
      padding: "0.5rem",
      fontSize: "inherit",
      lineHeight: "inherit",
      border: "1px solid",
      borderRadius: " 4px",
      color: "inherit",
      backgroundColor: "transparent",
      border: "none",
      backgroundColor: "#edf2f7",
      color: "#718096",
      outline: "none",
      borderRadius: "0.5rem",
      marginRight: "230px",
      marginTop:"8px",
      marginBottom:"8px"
    },
    button: {
      color: "white",
      backgroundColor: "#38bdf8",
      padding: "15px",
      borderRadius: "50px",
      border: "0px",
      fontSize: "15px",
      marginLeft: "120px",
      marginTop: "8px",
      marginBottom:"30px"
    }
  }
  const [contact, setContact] = useState({
    name: "",
    email: "",
    list:"",
    message: ""
  });


  const addData = (object) => {
    var axios = require('axios');
    var data = new FormData();
    var string = JSON.stringify(object)
    debugger
    data.append('from', 'Triaxo Solutions <postmaster@sandboxeda9308605114da0af134936e11b1524.mailgun.org>');
    data.append('to', 'shehryarkn@gmail.com');
    data.append('subject', 'Triaxo Solutions <triaxo.com>');
    data.append('text', string);
    data.append('domain', " sandboxeda9308605114da0af134936e11b1524.mailgun.org")

    var config = {
      method: 'post',
      url: 'https://api.mailgun.net/v3/sandboxeda9308605114da0af134936e11b1524.mailgun.org/messages',
      headers: {
        'Authorization': 'Basic YXBpOjk5NTA0MzBhZDE3ZDNlZTIxZDdhZjFmNzFkOTFlMmEzLTFiOGNlZDUzLTMzZGFjM2E1',
        // ...data.getHeaders()
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const addForm = async () => {

    try {
      var response = await addData(contact);
      console.log("add", response)
      alert("Message Send Sucessfully")
      // Swal.fire({
      //   position: 'top-center',
      //   icon: 'success',
      //   text: '' + response.data.message,
      //   title: 'Success',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    }
    catch (err) {
      console.log("error");
    }
  }


  return (
    <Modal
      id={identifier}
      contentStyles={{ maxWidth: hasMedia ? `narrow` : 500, p: 0 }}
    >
      <Flex
        sx={{
          alignItems: `stretch`,
          overflowY: `scroll`,
          height: `80vh`,
          maxHeight: `750px`,
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        {hasMedia && (
          <Box
            sx={{
              display: [`none`, null, `block`],
              minWidth: [null, null, null, 500],
              
              flex: 1,
              position: `relative`,
              borderRadius: reverse ? `right` : `left`,
              overflow: `hidden`
            }}
          >
            {images && (
              <ContentImages
              style={style.image}
                content={{ images }}
                imageFit='cover'
                height='0%'
                reverse={reverse}
              />
            )}
          </Box>
        )}
        <div style={style.div}>
          <h2 style={style.heading}>For Requesting Demo</h2>
          <form>
            <label>Name</label>
            <input style={style.input} type="text" name="name" onChange={(e) => { setContact({ ...contact, name: e.target.value }) }}   placeholder="Your name.." />
            {/* <br /> */}
            <label>Email</label>
            <input style={style.input} type="email" name="email" onChange={(e) => { setContact({ ...contact, email: e.target.value }) }} placeholder="Your email.." />
            {/* <br /> */}
            <label>List of Demos</label>
            <select style={style.input} type="select" name="list" onChange={(e) => { setContact({ ...contact, list: e.target.value }) }} >  
            <option> --- List --- </option>
              <option>Food Delivery App</option>
              <option>Restaurant App</option>
              <option>Contestant Voting App</option>
              <option>Rent a Parking Space App</option>
              <option>Shipment Tracking System</option>
              <option>Hospital Management Systems</option>
              <option>School Management Systems</option>
              <option>Assets Management Systems</option>
              <option>Language Learning App</option>
              
            </select>
            <label for="lname">Purpose</label>
            <textarea style={style.input} type="text" name='message'onChange={(e) => { setContact({ ...contact, message: e.target.value }) }}  placeholder="Your Message.." ></textarea>
            <button style={style.button} onClick={addForm} type="button">Send Message</button>
          </form>
        </div>
        {/* <Box sx={{ flex: 1, p: 5 }}>
          {text && (
            <>
              <Box sx={{ textAlign: `center` }}>
                <ContentText content={text} />
              </Box>
              <Divider space={3} />
            </>
          )}
          {collection?.map((props, index) => (
            <ListItem key={`item-${index}`} {...props} compact />
          ))}
          {form && <ContentForm form={form} id={`form.${identifier}`} />}
          {buttons && (
            <>
              <Divider space={2} />
              <ContentButtons
                content={buttons}
                wrapperStyles={{ textAlign: `center` }}
              />
            </>
          )}
        </Box> */}
      </Flex>
    </Modal>
  )
}

export default ModalBlock02
